import React from "react"

import { useApp } from "@hooks/useApp"
import { useCustomerRecover } from "@hooks/useCustomer"
import { useLanguage } from "@hooks/useLanguage"

export const withAccountForgotForm = Component => ({ name = `AccountForgotForm` }) => {
  const { recoverCustomer, data, setData, loading, errors, success } = useCustomerRecover()

  const locales = {
    ...useLanguage(`account`),
    ...useLanguage(`form`),
  }
  const {
    config: {
      settings: { routes },
    },
  } = useApp()

  const handleSubmit = async event => {
    event.preventDefault()
    await recoverCustomer(data?.email, false)
  }

  const handleChange = ({ target: { type, name, value, checked } }) => {
    setData(prevState => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : value,
    }))
  }

  const invalid = !data?.email

  Component.displayName = name
  return (
    <Component
      data={data}
      errors={errors}
      handleChange={handleChange}
      handleSubmit={handleSubmit}
      invalid={invalid}
      loading={loading}
      locales={locales}
      routes={routes}
      success={success}
    />
  )
}
