import React from "react"

import { withAccountForgot } from "./withAccountForgot"
import { Container } from "../../Styled/Container"
import { AccountForgotForm } from "./Form/AccountForgotForm"

export const AccountForgot = withAccountForgot(() => (
  <Container>
    <AccountForgotForm />
  </Container>
))
