import React from "react"
import { Link } from "gatsby"

import { withAccountForgotForm } from "./withAccountForgotForm"
import { Form, FormHeading, FormSubSubheading, BlockLabel, FormInput, FormButton, FormError } from "../../../Styled/Form"
import { StyledContainer } from "../../Login/AccountLoginStyles"

export const AccountForgotForm = withAccountForgotForm(
  ({ errors, email, handleChange, handleSubmit, invalid, loading, locales, routes, success }) => (
    <StyledContainer>
      <Form onSubmit={handleSubmit}>
        {success ? (
          <>
            <FormHeading noMargin>{locales?.recoverSuccessTitle}</FormHeading>
            <FormSubSubheading>{locales?.recoverSuccessMessage}</FormSubSubheading>

            <FormButton as={Link} to={routes.ACCOUNT_LOGIN}>
              {locales?.resetLoginButton}
            </FormButton>
          </>
        ) : (
          <>
            <FormHeading noMargin>{locales?.recoverTitle}</FormHeading>
            <FormSubSubheading>{locales?.recoverMessage}</FormSubSubheading>

            <BlockLabel>
              <FormInput name={`email`} onChange={handleChange} type={`email`} placeholder={locales?.emailAddress} value={email} autoFocus />
            </BlockLabel>

            <FormButton disabled={invalid || loading} type={`submit`}>
              {locales?.recoverSubmit}
            </FormButton>

            {errors?.length > 0 && errors?.map(({ code, message }) => <FormError key={code}>{message}</FormError>)}
          </>
        )}
      </Form>
    </StyledContainer>
  )
)
